import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import koKR from "antd/es/locale/ko_KR";
import "./App.less";

import Router from "./routes/Router";
import PersistentRecoil from "./components/PersistentRecoil";
import { SWRConfig } from "swr";
import api from "./api/api";
import moment from "moment";

function App() {
  const swrConfig = {
    fetcher(url, params) {
      api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  const varUA = navigator.userAgent;
  const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem("token")
  const fetchApi = api();
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') || sessionStorage.getItem("token")) {
      if (isFirst === true) {
        fetchData(1)
      }
    }
  }, [isFirst, token]);

  useEffect(() => {
    if (localStorage.getItem('token') || sessionStorage.getItem("token")) {
      const intervalId = setInterval(() => {
        fetchData(1);
      }, 20000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const fetchData = async (tempPage) => {
    try {
      const response = await fetchApi.get(`https://api.coffik.fifteenh.io/api/store/cafe/order?page=${tempPage}&status=0`);
      const newData = response.data.data.cafe_orders

      if (newData?.data.length > 0) {
        setIsFirst(false)

        if (varUA.match("coffikStore/Android") != null) {
          window.coffikStore.onAlarm()
        } else if (varUA.match("coffikStore/iOS") != null) {
          window.webkit.messageHandlers.onAlarm.postMessage("_");
        }

        setTimeout(() => {
/*          if (varUA.match("coffikStore/Android") != null) {
            window.coffikStore.postMessage(JSON.stringify({ type: "offAlarm" }));
          } else if (varUA.match("coffikStore/iOS") != null) {
            window.webkit.messageHandlers.offAlarm.postMessage({});
          }*/

          if (varUA.match("coffikStore/Android") != null) {
            window.coffikStore.offAlarm()
          } else if (varUA.match("coffikStore/iOS") != null) {
            window.webkit.messageHandlers.offAlarm.postMessage("_");
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <PersistentRecoil>
      <SWRConfig value={swrConfig}>
        <ConfigProvider locale={koKR}>
          <Router />
        </ConfigProvider>
      </SWRConfig>
    </PersistentRecoil>
  );
}

export default App;
